import { OAuth2AuthCodePkceClient,TokenResponse } from "oauth2-pkce";

interface ClickDetail {
    sessionID: number;
    page: string;
    contextTypeID: number;
    contextID: number;
    contentTypeID: number;
    contentID: number;
    templateID: number;
    permaLink: string;
    queryString: string;
  }
  
  const ROOT_DOMAIN = process.env.ROOT_DOMAIN;
  
  // Hydra endpoints
  const PUBLIC_HYDRA_ENDPOINT = process.env.PUBLIC_HYDRA_ENDPOINT;
  const AUTHORIZATIONURL = PUBLIC_HYDRA_ENDPOINT + '/oauth2/auth';
  const TOKENURL = PUBLIC_HYDRA_ENDPOINT + '/oauth2/token';
  // DN endpoints
  const SAVE_CLICK_URL = `${ROOT_DOMAIN}/createpageview`;
  const USER_DATA_URL = `${ROOT_DOMAIN}/drclick/api/user`;
  
  
  const LS_SESSIONID = "DOTTNET-SESSIONID";
  const HYDRA_COOKIE_PREFIX = 'oauth2';
  const LS_TOKEN = 'DRCLICK-TOKEN'; // openid token
  const LS_USER = 'DRCLICK-USER'; // oauth2 access token
  const LS_ACCESSTOKEN = 'DRCLICK-ACCESSTOKEN';

  const SESSION_DURATION = 30;
  const SESSION_COOKIE_NAME = 'drclick_client_session';
  
  
  const USER_DATA_PROPERTIES = [
    "idAnagrafica",
    "nome",
    "cognome",
    "email",
    "idCategoria",
    "categoria",
    "idComuneNascita",
    "comuneNascita",
    "idSpecializzazione",
    "specializzazione",
    "dataNascita",
    "sesso",
    "codiceFiscale",
    "dataPrivacyCliente",
    "ipPrivacyCliente",
    "flg1Active",
    "flg1Lbl",
    "flg1Value",
    "flg2Active",
    "flg2Lbl",
    "flg2Value",
    "flg3Active",
    "flg3Lbl",
    "flg3Value",
    "flg4Active",
    "flg4Lbl",
    "flg4Value"
  ];
  
  interface User {
    categoria: string,      // user category
    codiceFiscale: string,  // Italian personal identification code
    cognome: string,        // Surname
    comuneNascita: string,  // Birthplace
    dataNascita: Date,      // Birthdate
    dataPrivacyCliente: Date, // date the user gave privacy consent
    email: string,          // email (could be empty)
    flg1Active: boolean,    // first consent flag used true/false
    flg1Lbl: string,        // first consent flag label 
    flg1Value: boolean,     // first consent flag value
    flg2Active: boolean,    // first consent flag used true/false
    flg2Lbl: string,        // first consent flag label 
    flg2Value: boolean,     // first consent flag value
    flg3Active: boolean,    // first consent flag used true/false
    flg3Lbl: string,        // first consent flag label 
    flg3Value: boolean,     // first consent flag value
    flg4Active: boolean,    // first consent flag used true/false
    flg4Lbl: string,        // first consent flag label 
    flg4Value: boolean,     // first consent flag value
    idAnagrafica: number,   // user id
    idCategoria: number,    // category id
    idComuneNascita: number, // Birthplace id
    idSpecializzazione: number, // specialty id
    ipPrivacycliente: string,  // user ip address when the user gave privacy consent
    nome: string,           // name
    sesso: number,          // gender 0 male 1 female
    specializzazione: string, // specialty
  };
  
  
  
const enum Oauth2FlowStatus {
  COMPLETED = 1,
  // GOTAUTHORIZATIONCODE state is handled implicitly by isReturningFromAuthServer now
  HANDLING_REDIRECT = 2, // Renamed for clarity
  NOTSTARTED = 3,
  ERROR = 4 // Added for error state
}

const parametersToClear: string[] = ['code', 'scope', 'state'];


export function getDottnetSdk(
  clientId: string,
  callbackUrl: string,
  customerLoginUrl: string,
  scopes: string[],

): DottnetSdk {
  return new DottnetSdk(clientId, callbackUrl, customerLoginUrl, scopes);
}


export class DottnetSdk {

  clientPKCE: OAuth2AuthCodePkceClient;
  customerLoginUrl: string = '';
  callbackUrl: string;
  // currentOauth2FlowState: Oauth2FlowStatus; // Can be potentially removed or simplified if managePartialAuth fully handles state
  clientId: string;
  scopes: string[];
  private initializationPromise: Promise<void>; // Promise to track constructor async ops

  constructor(
    clientId: string,
    callbackUrl: string,
    customerLoginUrl: string,
    scopes: string[],
  ) {
    this.clientPKCE = this.createPKCEClient(clientId, callbackUrl, scopes);
    this.customerLoginUrl = customerLoginUrl || '';
    this.clientId = clientId;
    this.scopes = scopes;
    this.callbackUrl = callbackUrl;

    console.log("SDK Constructor: Initializing...");
    console.log("Client ID:", this.clientId);
    console.log("Callback URL:", this.callbackUrl);
    console.log('Customer Login URL:', this.customerLoginUrl);

    // Start async initialization but don't block constructor
    // Store the promise to potentially await it later if needed,
    // or ensure login() waits for it.
    this.initializationPromise = this.manageAuthFlowOnLoad();
  }

  // Renamed from managePartialAuth for clarity
  private async manageAuthFlowOnLoad(): Promise<void> {
    console.log('manageAuthFlowOnLoad: Checking auth state...');
    try {
      if (this.clientPKCE.isReturningFromAuthServer()) {
        console.log('manageAuthFlowOnLoad: Detected return from auth server. Handling redirect...');
        // Handle the redirect: get tokens, call customer login, clean URL
        await this.finalizeLogin();
        // currentOauth2FlowState = Oauth2FlowStatus.COMPLETED; // State is now effectively completed
      } else if (this.isLogged()) {
        console.log('manageAuthFlowOnLoad: User already logged in.');
        // currentOauth2FlowState = Oauth2FlowStatus.COMPLETED;
      } else {
        console.log('manageAuthFlowOnLoad: No active session and not returning from auth server.');
        // currentOauth2FlowState = Oauth2FlowStatus.NOTSTARTED;
      }
    } catch (err) {
      console.error('manageAuthFlowOnLoad: Error during initial auth state check:', err);
      // currentOauth2FlowState = Oauth2FlowStatus.ERROR;
      // Decide if you need to clear state here
      // this.reset(); // Optional: reset PKCE state on error?
    }
  }

  // Extracted logic for handling the redirect callback
  private async finalizeLogin(): Promise<void> {
    try {
      console.log('finalizeLogin: Attempting to get tokens...');
      const tokens = await this.getTokens(); // getTokens now returns tokens or throws
      console.log('finalizeLogin: Tokens obtained.');

      // Successful login. Set session cookie
      setClientSessionCookie(SESSION_COOKIE_NAME,SESSION_DURATION); // Set session cookie for 30 minutes
      console.log('finalizeLogin: Session cookie set for 30 minutes.');
      
      // Call customerLogin only ONCE after successful token exchange during redirect
      if (this.customerLoginUrl) {
        console.log('finalizeLogin: Calling customerLogin...');
        // Intentionally not awaiting customerLogin here if it involves navigation
        // If you NEED customerLogin to finish before login() resolves, await it.
         this.customerLogin().catch(err => console.error('Customer login failed after redirect:', err));
         // OR await this.customerLogin(); if subsequent steps depend on it
      }

      console.log('finalizeLogin: Cleaning URL parameters...');
      const newUrl = removeParametersFromUrl(window.location.href, parametersToClear);
      window.history.replaceState({}, '', newUrl);
      console.log('finalizeLogin: Redirect handling complete.');

    } catch (err) {
      console.error('finalizeLogin: Failed to handle redirect:', err);
      // Rethrow the error so initializationPromise reflects the failure
      throw err;
    }
  }


  isLogged(): boolean {
    // Check token existence AND validity (expiry)
    // Note: isAuthorized might just check for token existence, not expiry.
    // Adjust based on your library's specifics. Assuming isAuthorized checks expiry too.
    
    return this.clientPKCE.isAuthorized() && isClientSessionActive(SESSION_COOKIE_NAME);
       
        
    // Or be more explicit:
    // return !!localStorage.getItem(LS_ACCESSTOKEN) && !this.clientPKCE.isAccessTokenExpired();
  }


  /**
   * Checks login state. If logged in, returns user data.
   * If not logged in, initiates the authorization code flow (redirects the user).
   * Returns null if initiating login flow, or User data if already logged in.
   * Rejects the promise on error during state check or user data retrieval.
   */
  async login(): Promise<User | null> {
    // Wait for constructor's async initialization to complete first
    await this.initializationPromise;

    console.log('login: Checking login status...');

    if (this.isLogged()) {
      console.log('login: User is already logged in. Fetching user data...');
      try {
        // Fetch user data since we are logged in
        const user = await this.getUserData();
        console.log('login: User data retrieved for logged-in user:', user);
        return user; // Resolve with user data
      } catch (err) {
        console.error('login: Error fetching user data for logged-in user:', err);
        this.removeStorage(); // Maybe reset state if user data fails?
        throw err; // Reject the promise
      }
    } else {
      // Not logged in and constructor didn't handle a redirect token exchange
      console.log('login: User not logged in. Initiating authorization flow...');
      try {
        // This will redirect the user's browser
        await this.clientPKCE.requestAuthorizationCode();
        // Execution stops here due to redirect. Promise won't resolve in this case.
        return null; // Technically unreachable, but satisfies return type
      } catch (err) {
        console.error('login: Error initiating authorization flow:', err);
        throw err; // Reject the promise
      }
    }
  }

  private createPKCEClient(
      clientId: string,
      callbackUrl: string,
      scopes: string[]
    ): OAuth2AuthCodePkceClient {
      const clientPKCE = new OAuth2AuthCodePkceClient({
        scopes: scopes,
        authorizationUrl: AUTHORIZATIONURL,
        tokenUrl: TOKENURL,
        clientId: clientId,
        redirectUrl: callbackUrl,
        storeRefreshToken: false,
        // optional:
        onAccessTokenExpiry() {
          // when the access token has expired
          return this.clientPKCE.exchangeRefreshTokenForAccessToken();
        },
        onInvalidGrant() {
          // when there is an error getting a token with a grant
          console.warn(
            'createPKCEClient. Invalid grant! Auth code or refresh token need to be renewed.'
          );
          // you probably want to redirect the user to the login page here
        },
        onInvalidToken() {
          // the token is invalid, e. g. because it has been removed in the backend
          console.warn(
            'createPKCEClient. Invalid token! Refresh and access tokens need to be renewed.'
          );
          // you probably want to redirect the user to the login page here
        },
      });
  
      return clientPKCE;
    }
  
  
  
  async logout(postLogoutRedirectUri: string): Promise<void> { // Added return type
    console.log('logout: Initiating logout...');
    // Your existing logout logic seems reasonable: clear local storage, cookies, reset PKCE state.
    try {
      const idTokenHint = localStorage.getItem(LS_TOKEN);

      // Clear local data FIRST
      this.removeStorage(); // Resets PKCE client state

      console.log('logout: Local data cleared.');

      // Then redirect to the OIDC logout endpoint
      if (idTokenHint) {
         console.log('logout: Redirecting to OIDC logout endpoint.');
        window.location.replace(
          `${PUBLIC_HYDRA_ENDPOINT}/oauth2/sessions/logout?id_token_hint=${idTokenHint}&post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`
        );
      } else {
         console.log('logout: No id_token_hint found, redirecting directly to postLogoutRedirectUri.');
         window.location.replace(postLogoutRedirectUri); // Fallback redirect
      }
       // Code execution stops after location.replace
    } catch (err) {
      // Log error, but attempt fallback redirect anyway
      console.error("logout: Error during logout:", err);
       this.removeStorage(); // Ensure reset even on error
       window.location.replace(postLogoutRedirectUri); // Fallback redirect
    }
  }


  private removeStorage(): void {
      console.log('reset: Resetting PKCE client state.');
    this.clientPKCE.reset();
    deleteCookiesStartingWith(HYDRA_COOKIE_PREFIX);
    deleteStorageStartingWith(HYDRA_COOKIE_PREFIX); // Includes PKCE storage
    deleteCookiesStartingWith(SESSION_COOKIE_NAME); // Clear session cookie
    localStorage.removeItem(LS_USER);
    localStorage.removeItem(LS_ACCESSTOKEN);
    localStorage.removeItem(LS_TOKEN);
  //   localStorage.removeItem(LS_SESSIONID); // Assuming this should be cleared too
}

  // Modified getTokens to return tokens or throw error
  private async getTokens(): Promise<TokenResponse> {
    console.log('getTokens: Receiving code and exchanging for tokens...');
    // receiveCode might throw if code/state is invalid/missing
    try {
       if (!this.clientPKCE.isReturningFromAuthServer()) {
           throw new Error('Not returning from auth server, cannot exchange code.');
       }
      this.clientPKCE.receiveCode(); // Extracts code from URL
    } catch (err) {
        console.error('getTokens: Error receiving code from URL:', err);
        throw new Error(`Failed to receive code from URL: ${err.message || err}`);
    }


    try {
      // getTokens exchanges the code for tokens via fetch
      const tokens: TokenResponse = await this.clientPKCE.getTokens();
      if (!tokens || !tokens.idToken || !tokens.accessToken) {
         throw new Error('Received invalid tokens from server.');
      }
      // Store tokens immediately
      localStorage.setItem(LS_TOKEN, tokens.idToken);
      localStorage.setItem(LS_ACCESSTOKEN, tokens.accessToken);
      console.log('GetTokens: Tokens received and stored.');
      return tokens; // Return tokens on success
    } catch (err) {
      console.error('GetTokens: Failed to exchange code for tokens:', err);
      this.removeStorage(); // Reset state on token failure
      // Rethrow a more specific error
      throw new Error(`Failed to exchange authorization code for tokens: ${err.message || err}`);
    }
  }

  
  saveClick() {
    const params = new URLSearchParams(window.location.search);

    const consentChallenge = params.get("consent_challenge");

    const sessionId = localStorage.getItem(LS_SESSIONID);

    const clickDetail: ClickDetail = {
      sessionID: +sessionId,
      page: "consent",
      contextTypeID: 0,
      contextID: 0,
      contentTypeID: 0,
      contentID: 0,
      templateID: 0,
      permaLink: window.location.pathname,
      queryString: window.location.search,
    };

    const saveClickRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(clickDetail),
    };

    fetch(SAVE_CLICK_URL, saveClickRequestOptions)
      .then((response) => response.json())
      .then((data) => {
        try {
          console.log("saveClick. ecco response clickdetailid", data);
        } catch (error) {
          // TODO handle error
        }
      });
  }


  // customerLogin remains mostly the same, ensure error handling is robust
  async customerLogin(): Promise<void> { // Added return type
    if (!this.customerLoginUrl) {
      console.log('customerLogin: No URL configured. Skipping.');
      return;
    }

    let userData: User | null = null;
    try {
        // Ensure user data is fetched *before* calling the backend
        // If getUserData fails here, we shouldn't call the backend.
        userData = await this.getUserData();
    } catch (err) {
         console.error("customerLogin: Cannot call backend, failed to get user data first:", err);
         // Optionally rethrow or just return to prevent backend call
         throw new Error(`CustomerLogin prerequisite failed: ${err.message || err}`);
         // return;
    }


    if (userData === null) {
        // Should ideally be caught by the try/catch above, but double-check.
      console.error("customerLogin: Couldn't get user data. Aborting backend call.");
      return;
    }

    console.log('customerLogin: Calling backend URL:', this.customerLoginUrl);
    const customerLoginRequestOptions: RequestInit = { // Use RequestInit type
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
      credentials: "same-origin", // Explicit type
      redirect: "manual",        // Explicit type
    };

    try {
        const response = await fetch(this.customerLoginUrl, customerLoginRequestOptions);
        console.log('customerLogin: Backend response status:', response.status);

        // Handle different response statuses (redirect, success, error)
        if (response.status >= 200 && response.status < 300) {
             console.log('customerLogin: Backend call successful (2xx).');
            try {
                 const data = await response.json();
                 console.log('customerLogin: Backend response data:', data);
                 if (data?.location) { // Check for explicit redirect in JSON response
                      console.log('customerLogin: Redirecting based on JSON response:', data.location);
                      window.location.href = data.location;
                 }
            } catch (jsonError) {
                console.warn('customerLogin: Backend response was 2xx but not valid JSON or no location field.');
            }
        } else if (response.status >= 300 && response.status < 400 && response.headers.has('location')) {
             // Handle standard HTTP redirect
             const location = response.headers.get('location')!; // Not null asserted by .has()
             console.log('customerLogin: Redirecting based on Location header:', location);
             window.location.href = location;
        } else if (response.type === 'opaqueredirect') {
            // This happens with fetch + redirect: 'manual' + cross-origin redirect
             console.warn('customerLogin: Received opaqueredirect. Cannot follow automatically. Ensure backend allows origin or handles flow differently.');
        }
         else {
            // Handle backend errors (4xx, 5xx)
            console.error(`customerLogin: Backend call failed with status ${response.status} ${response.statusText}`);
             // Optionally read response body for error details if available
             // const errorBody = await response.text();
             // console.error('customerLogin: Error response body:', errorBody);
             throw new Error(`Customer login backend failed with status ${response.status}`);
        }
    } catch (error) {
        console.error('customerLogin: Network error or exception calling backend URL:', this.customerLoginUrl, error);
         throw error; // Rethrow network/fetch errors
    }
  }


  // getUserData remains largely the same, ensure it throws on failure
  async getUserData(): Promise<User | null> {
    const accessToken = localStorage.getItem(LS_ACCESSTOKEN);

    if ( !this.isLogged()) {
      this.removeStorage(); // Clear local storage and cookies
      console.warn('getUserData: User not logged in. Initiating login flow...');
      //await this.login(); // Ensure login flow is initiated if not logged in
      // If login() is successful, it will redirect and this function won't continue.
      return null;
    }
    // If the user is logged in, we can try to fetch user data
    if (!accessToken ) {
        console.warn('getUserData: No access token found in local storage.');
      // Instead of throwing, maybe return null to indicate not logged in?
      // Or keep throwing if an access token is strictly expected here.
      // Let's return null for consistency with login() return type option.
       return null;
      // throw new Error('getUserData: Oauth2 no access token present');
    }

    console.log('getUserData: Fetching data from', USER_DATA_URL);
    try {
        // Assuming callApiGet throws on network/HTTP error
      const user = await this.callApiGet<User>(USER_DATA_URL, accessToken);
       if (!user) {
           // Handle cases where the API might return empty success
           throw new Error('User data API returned empty response.');
       }
      localStorage.setItem(LS_USER, JSON.stringify(user)); // Cache user data
      return user;
    } catch (error) {
      console.error('getUserData: Failed to fetch or process user data:', error);
       // If fetching user data fails, maybe the token is invalid? Reset state.
       this.removeStorage();
       localStorage.removeItem(LS_USER); // Clear potentially stale cache
      throw error; // Rethrow the error to signal failure
    }
  }

  
  decodeToken(): any | null {
    if (!localStorage.getItem(LS_TOKEN)) {
      console.log('decodeToken. Oauth2 no user data present');
      return null;
    }

    const token = localStorage.getItem(LS_TOKEN);

    // decode JWT token 
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      // https://github.com/microsoft/TypeScript/issues/45566
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const jsonData = JSON.parse(jsonPayload);


    const userData = Object.keys(jsonData).reduce(function (obj, k) {
      if (USER_DATA_PROPERTIES.includes(k)) obj[k] = jsonData[k];
      return obj;
    }, {});

    return userData;
  }



  async callApiGet<T>(url: string, accessToken: string): Promise<T> | null {

    console.log('callApiGet. Url:', url);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return fetch(url, {
      credentials: "same-origin",
      mode: 'cors',
      method: 'GET',
      headers: headers
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json() as Promise<{ data: T }>
      })
      .then(data => {
        console.log('CallApiGet. risultato fetch ', data);
        return <T>data;
      })
      .catch((err: Error) => {
        console.error('callApiGet. error:',url, err);
        throw (err);
      })

  }


} // End of DottnetSdk class

function removeParametersFromUrl(url: string, parametersToBeRemoved: string[]): string {
    const urlObj: URL = new URL(url);
  
    // Rimuovi i parametri specificati dall'URL
    parametersToBeRemoved.forEach((parameter: string) => {
      urlObj.searchParams.delete(parameter);
    });
  
    // Restituisci l'URL aggiornato
    return urlObj.toString();
  }
  
  function deleteCookiesStartingWith(prefix: string) {
    var cookies: string[] = document.cookie.split(";");
  
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
  
      if (cookie.startsWith(prefix)) {
        let cookieName = cookie.split("=")[0];
        let cookieOptions = cookie.split(";").slice(1);
  
        // Construct the cookie deletion string with original options
        let deleteString = cookieName + "=;";
  
        for (let j = 0; j < cookieOptions.length; j++) {
          let option = cookieOptions[j].trim();
  
          if (option.toLowerCase().startsWith("path=") || option.toLowerCase().startsWith("domain=")) {
            deleteString += " " + option + ";";
          }
        }
  
        deleteString += " expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  
        document.cookie = deleteString;
      }
    }
  }
  
  function deleteStorageStartingWith(prefix: string) {
  
    const keys = Object.keys(localStorage);
    let i = keys.length;
  
    while (i--) {
      if (keys[i].startsWith(prefix)) {
        localStorage.removeItem(keys[i]);
      }
    }
  
    return;
  }
  
  function setClientSessionCookie(cookieName:string,minutes: number) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (minutes * 60 * 1000)); // 30 minutes in milliseconds
    document.cookie = `${cookieName}=active; expires=${expirationDate.toUTCString()}; path=/; secure`; // Add secure if HTTPS
  }

  function isClientSessionActive(cookieName: string = SESSION_COOKIE_NAME): boolean {
    
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cookieName) === 0) {
        return true;
      }
    }
    return false;
  }
  
  